import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";

// eslint-disable-next-line no-unused-vars
import Sidebar from "../layouts/Sidebar";
// eslint-disable-next-line no-unused-vars
import ReportModal from "../common/modal/reportModal";
// eslint-disable-next-line no-unused-vars
import BreadcrumbsReport from "../common/breadcrumb/breadCrumbsReport";
import { Table } from "antd";

// eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
import { formatDateISO, getCurrentDateISO, getExtendDateISO } from '../services/Helper';
import InvoiceService from '../services/invoice.service';


// eslint-disable-next-line no-unused-vars
import report from '../services/reports.service';
// eslint-disable-next-line no-unused-vars
import staticmessages from '../services/staticmessages';

import alertify from "alertifyjs";
import "../../node_modules/alertifyjs/build/css/alertify.css";
import "../../node_modules/alertifyjs/build/css/themes/semantic.css";
import { useHistory } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
import Spinners from '../components/spinners';

const ExpiringServices = () => {

  const history = useHistory();

  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true); // Loading state

  // eslint-disable-next-line no-unused-vars
  const [datasource, setInvoices] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [filteredDataSource, setFilteredDataSource] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [listType, setListType] = useState("invoicelist");

  //       <ReportModal setShow={setShow} show={show} customtitle={customtitle} listType = {listType} fdate = {fdate} tdate = {tdate} />

  // eslint-disable-next-line no-unused-vars

  let currentdate = new Date();
  let currentdateex = new Date(currentdate.getTime() - (30 * 24 * 60 * 60 * 1000));

  const [filteroption, setFilterOptions] = useState({ searchterm: "", fdate: currentdateex, tdate: currentdate });
  const [duplicate, setDuplicateAct] = useState(null);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };



  useEffect(() => {

    getExpiringServices();

  }, [filteroption]);

  const getExpiringServices = async () => {



    report.getExpiringServicesReport(listType, filteroption)
      .then((response) => {
        setInvoices(response.data);
        setFilteredDataSource(response.data);

        console.log(response);
        setLoading(false);

        // setInvoices([]);
        //  setFilteredDataSource([]);

      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleApplyFilter = (searchTerm) => {
    setShow(false);

    setSearchTerm(searchTerm);

    setFilterOptions((prev) => ({
      ...prev,
      searchterm: searchTerm
    }));

    getExpiringServices(); // Reload parent data
  };

  const handleApplyResetApply = () => {

    setFilterOptions((prev) => ({
      ...prev,
      searchterm: "", fdate: currentdate, tdate: currentdateex
    }));


    setShow(false);
    getExpiringServices(); // Reload parent data
  };


  // eslint-disable-next-line no-unused-vars
  const setDuplicate = (objdata) => {

    console.log(objdata)

    if (objdata) {
      setDuplicateAct(objdata);
    }
  };
  const columns = [
   

    {
      title: "#",
      dataIndex: "id",
      render: (text, record, index) => (
        <>
          <div className="table-invoice  center-center ">
            { index + 1}
          </div>

          <div className="table-invoice  center-center mobile-only">
          <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="btn-action-icon"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-h" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
        
             

                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#duplicate_modal"
                  onClick={() => setDuplicate(record)}
                >
                 <i className="far fa-add me-2" />
                 Proforma Invoice
                </Link>


                <button type="button" className="dropdown-item" onClick={() => handaleSubmenu("print", listType, record)}>
                  <i className="fas fa-print me-2" />
                  Print
                </button>

               
              
              </div>
            </div>
          </div>
        </>

      ),
    },

    {
      title: "Invoice No.",
      dataIndex: "invoice_number",
      render: (text, record) => (
        <>
          <span>{record.invoice_number}</span>
        </>
      ),
      sorter: (a, b) => {
        // Make sure both a and b have client_name defined
        if (!a.invoice_number) return 1;
        if (!b.invoice_number) return -1;

        // Handle sorting based on client_name, assuming it is a string
        return a.invoice_number.localeCompare(b.invoice_number);
      },
    },


    {
      title: "Client",
      dataIndex: "client_name",
      render: (text, record) => (
        <>
          <span>{record.client_name}</span>
        </>
      ),
      sorter: (a, b) => {
        // Make sure both a and b have client_name defined
        if (!a.client_name) return 1;
        if (!b.client_name) return -1;

        // Handle sorting based on client_name, assuming it is a string
        return a.client_name.localeCompare(b.client_name);
      },
    },
    {
      title: "Total Amount",
      dataIndex: "gtotal",
      render: (img, record) => (
        <>
          {record.symbol}{record.gtotal}
        </>
      ),

    },
    {
      title: "Date",
      dataIndex: "datetimestamp",
      render: (text, record) => (
        <>

          <span>{formatDateISO(record.datetimestamp)}</span>


        </>),
      sorter: (a, b) => a.datetimestamp.length - b.datetimestamp.length,
    },

    {
      title: "Due Date",
      dataIndex: "datetimestampdue",
      render: (text, record) => (
        <>

          <span>{formatDateISO(record.datetimestampdue)}</span>


        </>),
      sorter: (a, b) => a.datetimestampdue.length - b.datetimestampdue.length,
    },

    {
      title: "Amount",
      dataIndex: "gtotal",
      render: (text, record) => (
        <>

          <span> {record.symbol}{record.gtotal}</span>

        </>
      ),
      sorter: (a, b) => a.gtotal.length - b.gtotal.length,
    },


    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (

        <div className="text-end">
          <div className="dropdown dropdown-action">
            <Link
              to="#"
              className="btn-action-icon"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v" />
            </Link>
            <div className="dropdown-menu dropdown-menu-end">



              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#duplicate_modal"
                onClick={() => setDuplicate(record)}
              >
                <i className="far fa-add me-2" />
                Proforma Invoice
              </Link>


              <button type="button" className="dropdown-item" onClick={() => handaleSubmenu("print", listType, record)}>
                <i className="fas fa-print me-2" />
                Print
              </button>



            </div>
          </div>
        </div>

      ),

    },
  ];


  // eslint-disable-next-line no-unused-vars
  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };
  const toastrError = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.error(message);
  };

  // eslint-disable-next-line no-unused-vars
  const handleDuplicate = async (listType, objdata) => {
    if (objdata) {
      try {
        const response = await InvoiceService.createProfomaInvoice(listType, objdata);

        console.log(response.invoice_number);

        // Assuming response contains some data or status you might want to handle
        if (response.invoice_number) {
          const invoiceNumber = response.invoice_number; // Assuming response.data contains the invoice_number
          let mess = `${invoiceNumber} ${staticmessages.INVOICE_PROFORM_CSUCCESS}`;

          toastrSuccess(mess);
          //getExpiringServices(); // Update the services after successful duplication
          history.push('estimate-list');

        } else {
          // Handle any other status codes or conditions here
          console.warn('Unexpected response:', response);
        }
      } catch (error) {
        console.error('Duplication Action Failed:', error);
        // Optionally, display an error message to the user
        toastrError('Duplication failed. Please try again.');
      }
    }
  };


  // eslint-disable-next-line no-unused-vars
  // const handleDuplicate = (listType, objdata) => {
  //   if (objdata) {
  //     InvoiceService.createProfomaInvoice(listType, objdata)
  //       .then(() => {
  //         //let mess = `${customtitle} ${staticmessages.INVOICE_DELSUCCESS}`;
  //         let mess = `${staticmessages.INVOICE_PROFORM_CSUCCESS}`;
  //         toastrSuccess(mess);
  //         getExpiringServices();
  //       })
  //       .catch((error) => {
  //         console.error('Delete Action Failed:', error);
  //       });
  //   }
  // };

  // eslint-disable-next-line no-unused-vars
  const handaleSubmenu = (type, listType, selectedinvoice) => {



    switch (listType) {
      case "invoicelist":
        console.log("handleSubmenu handleSubmenu: " + type + "---" + listType);
        switch (type) {
          case "print":


            InvoiceService.handleDownloadPdfInvoiceEST("Invoice", listType, selectedinvoice);


            // Add your print handling code here
            break;

          case "proformainv":
            history.push(`/client-ledger`, { selectedinvoice });
            break;


          default:
            break;
        }
        break;

      case "estimatelist":
        console.log("handleSubmenu handleSubmenu: " + type + "---" + listType);
        switch (type) {
          case "print":
            InvoiceService.handleDownloadPdfInvoiceEST("Invoice", listType, selectedinvoice);
            break;

          case "proformainv":
            history.push(`/client-ledger`, { selectedinvoice });
            break;



          default:
            break;
        }
        break;

      default:
        break;
    }


  };

  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");

  const forceMenuClose = () => {
    setMenu(false);
  };


  if (loading) {
    return <Spinners />; // Display the spinner while loading
  }

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose} onApplyFilter={handleApplyFilter} searchTerm={searchTerm} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">

            <BreadcrumbsReport
              title={staticmessages.REPORT_EXPIRINGSERVICES}
              show={show}
              setShow={setShow}
            />
            <ReportModal setShow={setShow} show={show} filteroption={filteroption} setFilterOptions={setFilterOptions}
              handleApplyFilter={handleApplyFilter} handleApplyResetApply={handleApplyResetApply} />

            <div className="row">
              <div className="col-sm-12">
                <div className="card invoiceList">
                  <div className="card-body invoiceList">
                    <div className="table-responsive table-hover">
                      <Table
                        className="table"
                        pagination={{
                          total: filteredDataSource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,

                        }}
                        columns={columns}
                        dataSource={filteredDataSource}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div
        className="modal custom-modal fade"
        id="duplicate_modal"
        role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>Create Proforma Invoice</h3>
                {duplicate && (
                  <p>Are you sure you want to create new Proforma Invoice of this Invoice {duplicate.invoice_number}?</p>
                )}
              </div>
              <div className="modal-btn delete-action">
                <div className="row">

                  <div className="col-6">
                    <button
                      type="submit"
                      data-bs-dismiss="modal"
                      className="w-100 btn btn-primary "
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      type="reset"
                      data-bs-dismiss="modal"
                      className="w-100 btn btn-primary paid-continue-btn"
                      onClick={() => handleDuplicate(listType, duplicate)}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  );
};

export default ExpiringServices;
