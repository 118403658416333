import React, { useState, useEffect } from "react";
import Header from '../layouts/Header';
import Sidebar from '../layouts/Sidebar';
import SettingsSidebar from '../layouts/SettingsSidebar';


import staticmessages from '../services/staticmessages';
import alertify from "alertifyjs";
import * as yup from 'yup'; 
import "../../node_modules/alertifyjs/build/css/alertify.css";
import UserService from "../services/user.service";

const PaymentMethod = () => {
  const [profile, setProfile] = useState({
    partial_url: "",
    payment_url: "",
    bank_details: "",
  });

   // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      await fetchProfile();
    };
    fetchData();
  }, []);

  const fetchProfile = async () => {
    try {
      const response = await UserService.getUserProfile();
      const { partial_url, payment_url,bank_details } = response.data;
      setProfile({
        partial_url: partial_url || '',
        payment_url: payment_url || '',
        bank_details: bank_details || '',
      });
     
    } catch (error) {
      console.error("UserService error:", error);
    }
  };

  const schema = yup.object().shape({
    partial_url: yup.string().notRequired(),
  });
  const toastrError = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.error(message);
  };

  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };

  const handleSubmit = async (e) => {

    console.log("handleSubmit")
    e.preventDefault();
    try {
      await schema.validate(profile, { abortEarly: false });
      await UserService.updatePaymentSetting(profile);
      setErrors({});
      toastrSuccess(staticmessages.SETTING_PAYMENTSUCCESS);
    } catch (validationError) {
      if (validationError instanceof yup.ValidationError) {
        const errorsObj = {};
        validationError.inner.forEach((error) => {
          errorsObj[error.path] = error.message;
        });
        console.log(errorsObj)

        setErrors(errorsObj);
      } else {

        toastrError(validationError.message);
        console.error(validationError);
      }
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  
  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const forceMenuClose = () => {  
    setMenu(false);
  };
  
  return (
    <>
       <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
  
   <Header onMenuClick={() => toggleMobileMenu()}  forceMenuClose={forceMenuClose} />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SettingsSidebar />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="card">
                <div className="card-body w-100">
                  <div className="content-page-header">
                    <h5>{staticmessages.SETTING_PAYMENT}</h5>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="row">

                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Your UPI ID</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter UPI ID"
                          onChange={handleInputChange}
                          id="partial_url"
                          name="partial_url"
                          value={profile.partial_url}
                        />
                         <small className="error">{errors?.partial_url}</small>
                      </div>
                    </div>

                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Your Bank Details (Optional)</label>
                        <input
                          type="text"
                          className="form-control"
                           placeholder="Enter Bank Details"
                          onChange={handleInputChange}
                          id="bank_details"
                          name="bank_details"
                          value={profile.bank_details}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Link to Payment Gateway</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Payment Gateway"
                          onChange={handleInputChange}
                          id="payment_url"
                          name="payment_url"
                          value={profile.payment_url}
                        />
                      </div>
                    </div>


                     
                      <div className="col-md-12">
                        <div className="modal-footer p-0">
                          <button 
                            type="submit"
                            className="btn btn-primary"
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default PaymentMethod;
