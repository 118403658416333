import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation, Link } from 'react-router-dom';
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import "../../_components/antd.css";
import { Table } from "antd";
// eslint-disable-next-line no-unused-vars
import Select from "react-select";
// eslint-disable-next-line no-unused-vars
import DatePicker from "react-datepicker";
// eslint-disable-next-line no-unused-vars
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';

// eslint-disable-next-line no-unused-vars
import ViewClientLedgerAddPayment from "./viewClientLedgerAddPayment";
import AddVendorPayment from "../../customers/vendors/addVendorPayment";

import InvoiceService from '../../services/invoice.service';
// eslint-disable-next-line no-unused-vars
import { formatDateISO, getCurrentDateISO, getExtendDateISO } from '../../services/Helper';

import * as yup from "yup";
import alertify from "alertifyjs";
import "../../../node_modules/alertifyjs/build/css/alertify.css";
import "../../../node_modules/alertifyjs/build/css/themes/semantic.css";

import staticmessages from '../../services/staticmessages';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


const ViewLedger = () => {

  const targetButtonRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState({}); // State to hold validation errors

  // eslint-disable-next-line no-unused-vars
  const [listType, setListType] = useState("invoicelist");

  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [selectedOption1, setSelectedOption1] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [startInvoiceDate, setStartInvoiceDate] = useState(new Date());


  // eslint-disable-next-line no-unused-vars
  const [showSelect, setShowSelect] = useState(false);
  const openPaymentForm = () => {
    setEditSelect(true);
    setPaymentData((prev) => ({
      ...prev,
      invoice_id: "",
      uniqueno: "",
      dateformat: "",
      symbol: "",
      paymentModeType: "invoicepayment",
      amount: "",
      newamount: "",
      payment_mode: "",
      id: "",

    }));
  };


  const [editSelect, setEditSelect] = useState(false);


  const handleCloseSelect = () => {
    setShowSelect(false);
    setErrors({});
  };

  const handleCloseEditSelect = () => {
    setEditSelect(false);
    setErrors({});
  };


    // eslint-disable-next-line no-unused-vars
  const handaleEditPayment = () => {


    setShowSelect(false);
    setEditSelect(true);
  };





  // eslint-disable-next-line no-unused-vars
  const options1 = [
    { value: "", label: "Select Payment Method" },
    { value: "Cash", label: "Cash" },
    { value: "Bank", label: "Bank" },
    { value: "TDS", label: "TDS" },
  ];

  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [client, setClientDetail] = useState(false);


  // eslint-disable-next-line no-unused-vars
  const [paymentAct, setSelectedPayemntAct] = useState(null);


  let currentdate = new Date();
  let currentdateex = new Date(currentdate.getTime() - (30 * 24 * 60 * 60 * 1000));

  const [filteroption, setFilterOptions] = useState({ client_id: "", linkModeType: "", fdate: currentdateex.toISOString(), tdate: currentdate.toISOString(), uniqueno: "", filterText: "" });
  const [selectedinvoice, setSelectedInvoice] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [datasource, setLoadData] = useState([]);

  const [dataclosebal, setDataCloseBal] = useState({ cr_sum: 0, dr_sum: 0, closingbal_sum_dr: 0, closingbal_sum_cr: 0 });

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };


  const [currsymbol, setCurrSymbol] = useState("");


  // eslint-disable-next-line no-unused-vars
  const handleParentViewLedger = () => {
    console.log("handleParentViewLedger 2222");

  };
  // eslint-disable-next-line no-unused-vars
  const [customtitle, setCustomTitle] = useState(staticmessages.PAYMENT_LIST_TITLE);
  const [payment, setPaymentData] = useState({
    client_id: "", linkModeType: "", payment_date: currentdate,
    invoice_id: "",
    uniqueno: "",
    dateformat: "",
    symbol: "",
    paymentModeType: "invoicepayment",
    amount: "",
    newamount: "",
    payment_mode: "",
    id: "",

  });
  // eslint-disable-next-line no-unused-vars
  const handleChangeAmount = (e) => {
    const { value } = e.target;

    setPaymentData((prev) => ({
      ...prev,
      amount: value,
      newamount: value,
    }));

  };
  // eslint-disable-next-line no-unused-vars
  const handlePamyentMethod = (value) => {
    setSelectedOption1(value);

    setPaymentData((prev) => ({
      ...prev,
      payment_mode: value.value
    }));

  };
  // eslint-disable-next-line no-unused-vars
  const HandaleDate = (value) => {

    console.log(value);
    setStartInvoiceDate(value);
    setPaymentData((prev) => ({
      ...prev,
      payment_date: value
    }));

  };


  const getUser = async () => {
    try {
      let res = await InvoiceService.getUserProfile();

      setSelectedInvoice((prev) => ({
        ...prev,
        symbol: res.data.symbol,
        linkModeType: "invoicelist",

        dateformat: res.data.dateformat,
      }));

      setCurrSymbol(res.data.symbol);

      console.log("getUser=======");
      console.log(res.data.symbol);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);


  useEffect(() => {

    setPaymentData((prev) => ({
      ...prev,
      symbol: currsymbol

    }));

  }, [currsymbol]);


  useEffect(() => {
    console.log("ViewLedger Data");


    if (selectedinvoice) {


      // Calculate sum of cr_amount and dr_amount
      const sumCrAmount = datasource.reduce((total, item) => total + item.cr_amount, 0);
      const sumDrAmount = datasource.reduce((total, item) => total + item.dr_amount, 0);

      let closingBalanceCr = 0;
      let closingBalanceDr = 0;

      if (sumCrAmount > sumDrAmount) {
        closingBalanceCr = sumCrAmount - sumDrAmount;
        closingBalanceCr = closingBalanceCr.toFixed(2);
      }
      if (sumDrAmount > sumCrAmount) {
        closingBalanceDr = sumDrAmount - sumCrAmount;
        closingBalanceDr = closingBalanceDr.toFixed(2);
      }

      console.log(sumCrAmount + '---' + sumDrAmount + '---' + closingBalanceCr + '::' + closingBalanceDr);



      // Update state with calculated sums `Showing ${range[0]} to ${range[1]} of ${total} entries`,
      setDataCloseBal({
        cr_sum: `${selectedinvoice.symbol}${sumCrAmount.toFixed(2)}`,
        dr_sum: `${selectedinvoice.symbol}${sumDrAmount.toFixed(2)}`,
        closingbal_sum_dr: closingBalanceDr > 0 ? `${selectedinvoice.symbol}${Math.abs(closingBalanceDr)} Dr` : '',
        closingbal_sum_cr: closingBalanceCr > 0 ? `${selectedinvoice.symbol}${Math.abs(closingBalanceCr)} Cr` : '',

      });
    }

  }, [datasource]);


  useEffect(() => {

    console.log("ClientLedger   3333", JSON.stringify(location.state?.selectedinvoice.customer, null, 2));

    if (location.state?.selectedinvoice.customer && location.state?.selectedinvoice.client_name) {
  
      console.log("ViewLedger 77777");
      console.log(location.state?.selectedinvoice);
      setSelectedInvoice((prev) => ({
        ...prev,
        client_id: location.state?.selectedinvoice.customer.id,
        uniqueno: location.state?.selectedinvoice.uniqueno,
        linkModeType: "invoicelist",
        symbol: location.state?.selectedinvoice.symbol,
        dateformat: location.state?.selectedinvoice.dateformat,
      }));





      setFilterOptions((prev) => ({
        ...prev,
        client_id: location.state?.selectedinvoice.customer.id,
        uniqueno: location.state?.selectedinvoice.uniqueno,
        linkModeType: "invoicelist",
      }));

      setSelectedInvoice(location.state?.selectedinvoice);
      setClientDetail(location.state?.selectedinvoice.customer)
    }

  }, [location.state?.selectedinvoice]);

  useEffect(() => {
    console.log("ViewLedger Data");
    if (client) {
      getLedgerCurrency(client.id);
      getLedgerData(filteroption);

      setFilterOptions((prev) => ({
        ...prev,
        client_id: client.id,

        linkModeType: "invoicelist",
      }));





      setPaymentData((prev) => ({
        ...prev,
        client_id: client.id,
        linkModeType: "invoicelist",
      }));


    }


  }, [client]);

  const getLedgerData = async (filteroption) => {

    console.log("getLedgerData filteroption", filteroption);
    try {
      let res = await InvoiceService.getcustomerledgermainreport(filteroption);

      setLoadData(res.data);
      console.log("getLedgerData", res.data);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const getLedgerCurrency = async (client_id) => {
    try {
      let res = await InvoiceService.getCustomerTransCurr(client_id);
      //  setLoadData(res.data);
      console.log("getLedgerData", res);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };


  const schema = yup.object({

    amount: yup.string()
    .matches(/^\d+(\.\d+)?$/, 'Amount can only contain numeric characters and an optional decimal point')
    .required('Amount is required')
    .test('is-positive', 'Amount must be greater than 0', value => {
      const numberValue = parseFloat(value);
      return !isNaN(numberValue) && numberValue > 0;
    }),
  
    payment_mode: yup.string().required("Payment Method is required"),
    payment_date: yup.string().required("Payment Date is required"),
  });

  const toastrError = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.error(message);
  };
  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };


  // eslint-disable-next-line no-unused-vars
  const handaleSubmenu = (type, listType, record) => {

    console.log("------handaleSubmenu ===== ");
    console.log(record);


    const selectedinvoice = location.state?.selectedinvoice || {};

    setPaymentData(prevState => ({
      ...prevState,
      ...record, // Spread the properties of record into the state
      client_id: selectedinvoice.customer?.id || "",
    }));


    setStartInvoiceDate(record.payment_date)

    //setPaymentData(record);



    setSelectedOption1({ value: record.payment_mode, label: record.payment_mode })

    setShowSelect(true);
  };


  // Handle row click
  const handleRowClick = (record) => {
    if (record.paymentModeType !== "invoicelist" && record.brought_forward !== "Brought Forward") {
      // Perform action here, e.g., edit
      handaleSubmenu("edit", listType, record);
    }
  };


  // eslint-disable-next-line no-unused-vars
  const handaleSavePayment = () => {


    if (startInvoiceDate instanceof Date) {
      console.log("------handaleSavePayment aaa ===== " + startInvoiceDate);
      // Check if the date is already in the desired format


      setPaymentData(prevState => ({
        ...prevState,
        ...payment, // Spread the properties of record into the state
        symbol:currsymbol,
        payment_date: startInvoiceDate,
        client_id: location.state?.selectedinvoice.customer.id,
      }));

      setCallApi(new Date());
    } else if (typeof startInvoiceDate === 'number') {
      // Convert timestamp to Date and format
      const date = new Date(startInvoiceDate);
      const formattedDate = date.toISOString();
      setPaymentData(prevState => ({
        ...prevState,
        ...payment, // Spread the properties of record into the state
        payment_date: formattedDate,
        client_id: location.state?.selectedinvoice.customer.id,
      }));
      setCallApi(new Date());
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [callapi, setCallApi] = useState(null);

  useEffect(() => {
    if (callapi) {
      console.log("------handaleSavePaymentFianlCall zzzz ===== " + startInvoiceDate + "--" + payment.payment_date);
      handaleSavePaymentFianlCall()
    }

  }, [callapi]); // 

  // eslint-disable-next-line no-unused-vars
  const handaleSavePaymentFianlCall = () => {

    console.log("------handaleSavePaymentFianlCall aaa ===== " + JSON.stringify(payment, null, 2));

    schema.validate(payment, { abortEarly: false })
      .then(() => {
        const action = payment.id ? InvoiceService.updatePaymentcustomer : InvoiceService.addPaymentcustomer;


        console.log("------handaleSavePayment ===== " + action);

        console.log(payment);

        action(payment.id, payment, listType)
          .then(() => {
            setCallApi(null);

            if (payment.id) {
              let mess = `${customtitle} ${staticmessages.PAYMENT_USUCCESS}`;
              toastrSuccess(mess);
              getLedgerData(filteroption);
              window.location.reload();

            }
            else {
              let mess = `${customtitle} ${staticmessages.PAYMENT_CSUCCESS}`;
              toastrSuccess(mess)
              getLedgerData(filteroption);
              window.location.reload();
            }

            if (targetButtonRef.current) {
              targetButtonRef.current.click();
            }


          })
          .catch((error) => {
            setError(error.message);
            toastrError(error.message);
          });
      })
      .catch((validationErrors) => {
        // Yup validation errors
        const errorsObj = {};
        validationErrors.inner.forEach((error) => {
          errorsObj[error.path] = error.message;
        });
        setErrors(errorsObj);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const [deleteaction, setDeleteAction] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [deleteact, setDeleteAct] = useState(null);
  // eslint-disable-next-line no-unused-vars

  // eslint-disable-next-line no-unused-vars
  const [showDelete, setShowDelete] = useState(false);

  const handleCloseDelete = () => {

    setShowDelete(false);

  };

  const handleDeleteSelectActionConfirm = () => {
    setDeleteAct(payment);
    console.log(payment);
    setShowDelete(true);
  };
  const handleDeleteSelectAction = () => {
   
    console.log("------handleDelete ===== " + payment);
    console.log(payment);

    if (payment) {
      InvoiceService.deletePaymentCustomer(payment)
        .then(() => {
          let mess = `${customtitle} ${staticmessages.INVOICE_DELSUCCESS}`;
          toastrSuccess(mess);
          getLedgerData(filteroption);
          setEditSelect(false);
          setShowDelete(false);
        })
        .catch((error) => {
          console.error('Delete Action Failed:', error);
        });
    }

  };

  const handleApplyFilterOptions = () => {
    setShow(false);

   
    getLedgerData(filteroption);
  };
  
  const clickDownload = () => {

    console.log(filteroption);

   InvoiceService.handleDownloadPayments(filteroption);
  

    // report.getledgerData(listType, filteroption, "export", client.id)
    //   .then((response) => {
    //     // setInvoices(response.data);
    //     // setFilteredDataSource(response.data);



    //     console.log(response);



    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

  };

  

  const handaleEditPaymentnew = (record) => {

    setPaymentData(record);

    setSelectedOption1({value:record.payment_mode, label:record.payment_mode});
    // setSelectedClient({
    //   value: record.id,
    //   label: record.client_name,
    // });


    setStartInvoiceDate(record.payment_date);

    handaleEditPayment();
   
  };

  const handleDeleteSelectActionConfirmnew = (record) => {
    setPaymentData(record);

    setSelectedOption1({value:record.payment_mode, label:record.payment_mode});
    // setSelectedClient({
    //   value: record.id,
    //   label: record.party_name,
    // });


    setStartInvoiceDate(record.payment_date);

    handleDeleteSelectActionConfirm();
   
  };



  const columns = [


    {
      title: "#",
      dataIndex: "id",
      render: (text, record, index) => (
        <>
          <div className="table-invoice  center-center ">
            {index + 1}
          </div>


          {record.paymentModeType === "invoicelist" || record.brought_forward === "Brought Forward" ? (
            <span></span>
          ) : (

          <div className="table-invoice  center-center mobile-only">
            <div className="dropdown dropdown-action">
            <Link
              to="#"
              className=" btn-action-icon "
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v" /> 
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <ul>
                <li>
                <button className="dropdown-item" onClick={() => handaleEditPaymentnew(record)}>
                
                    <i className="far fa-edit me-2" />
                    Edit
                  </button>
                </li>
                <li>
          
                  <Link
                    className="dropdown-item"
                    to="#"
             
                    onClick={() => handleDeleteSelectActionConfirmnew(record)}>
                    <i className="far fa-trash-alt me-2" />
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          </div>
           )}
        
</>

      ),
    },

    {
      title: "Date",
      dataIndex: "payment_date",
      render: (text, record) => (
        <span
        className={
          record.paymentModeType === "invoicelist" || record.brought_forward === "Brought Forward"
            ? "inherit-text"
            : "blue-text-link"
        }
        onClick={() => handleRowClick(record)}
      >
          {formatDateISO(record.payment_date)}
        </span>
      ),
     
    },
    {
      title: "",
      dataIndex: "payment_date",
      render: (text, record) => (
        <span
        className={
          record.paymentModeType === "invoicelist" || record.brought_forward === "Brought Forward"
            ? "inherit-text"
            : "blue-text-link"
        }
        onClick={() => handleRowClick(record)}
      >
          {record.brought_forward}
        </span>
      ),
      
    },
    {
      title: "Debit",
      dataIndex: "dr_amount",
      render: (text, record) => (
        <span
        className={
          record.paymentModeType === "invoicelist" || record.brought_forward === "Brought Forward"
            ? "inherit-text"
            : "blue-text-link"
        }
        onClick={() => handleRowClick(record)}
      >
          {record.symbol}{record.dr_amount}
        </span>
      ),
     
    },
    {
      title: "Credit",
      dataIndex: "cr_amount",
      render: (text, record) => (
        <span
        className={
          record.paymentModeType === "invoicelist" || record.brought_forward === "Brought Forward"
            ? "inherit-text"
            : "blue-text-link"
        }
        onClick={() => handleRowClick(record)}
      >
          {record.symbol}{record.cr_amount}
        </span>
      ),
     
    },
    {
      title: "Payment Method",
      dataIndex: "payment_mode",
      render: (text, record) => (
        <span
        className={
          record.paymentModeType === "invoicelist" || record.brought_forward === "Brought Forward"
            ? "inherit-text"
            : "blue-text-link"
        }
        onClick={() => handleRowClick(record)}
      >
          {record.payment_mode}
        </span>
      ),
    
    },
    
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (

        <>

        {record.paymentModeType === "invoicelist" || record.brought_forward === "Brought Forward" ? (
          <span></span>
        ) : (
        <div className="d-flex align-items-center">
          <div className="dropdown dropdown-action">
            <Link
              to="#"
              className=" btn-action-icon "
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v" /> 
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <ul>
                <li>
                <button className="dropdown-item" onClick={() => handaleEditPaymentnew(record)}>
                
                    <i className="far fa-edit me-2" />
                    Edit
                  </button>
                </li>
                <li>
          
                  <Link
                    className="dropdown-item"
                    to="#"
             
                    onClick={() => handleDeleteSelectActionConfirmnew(record)}>
                    <i className="far fa-trash-alt me-2" />
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

)}
        </>

        
      ),
     
    },




  ];

  const forceMenuClose = () => {  
    setMenu(false);
  };


  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()}  forceMenuClose={forceMenuClose}  />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}

           
            
                <ViewClientLedgerAddPayment setShow={setShow} show={show} client={client} selectedinvoice={selectedinvoice} openPaymentForm={openPaymentForm} clickDownload={clickDownload} />




            {/* Table */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body ViewLedger">
                    <div className="table-responsive table-hover">
                      <Table
                        pagination={{
                          total: datasource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          // showSizeChanger: true,
                          pageSize: 50, // Set the number of entries per page

                        }}

                        // onRow={(record) => {
                        //   return {
                        //     onClick: () => handleRowClick(record),
                        //   };
                        // }}
                        columns={columns}
                        dataSource={datasource}
                      />


                      <div className="row">
                        <div className="col-lg-6">

                        </div>
                        <div className="col-lg-6">
                          <div className="card">
                            <div className="card-header">
                              <h5 className="card-title">Closing Balance </h5>
                            </div>
                            <div className="card-body">
                              <div className="table-responsive">
                                <table className="table table-striped mb-0">

                                  <tbody>
                                    <tr>
                                      <td>Total</td>
                                      <td>{dataclosebal.dr_sum} Dr</td>
                                      <td>{dataclosebal.cr_sum} Cr</td>
                                    </tr>
                                    <tr>
                                      <td>Closing Balance</td>
                                      <td>{dataclosebal.closingbal_sum_dr}</td>
                                      <td>{dataclosebal.closingbal_sum_cr}</td>
                                    </tr>

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Table */}
          </div>
        </div>

        <AddVendorPayment setShow={setShow} show={show} setFilterOptions ={setFilterOptions} handleApplyFilterOptions = {handleApplyFilterOptions}  />


      </div>







      {/* Add Expenses Modal */}




      <Modal show={showSelect} onHide={handleCloseSelect}>
        <Modal.Header >
          <Modal.Title>View Receipt </Modal.Title>

          <div className="d-flex ms-auto align-items-center">
          <button
        type="button"
        className="btn-close ms-2"
        onClick={handleCloseSelect}
        aria-label="Close"
      /></div>
        </Modal.Header>
        <Modal.Body  >

          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <div className="border-0 pb-0">
                  <div className="row">


                  <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="input-block mb-3">
                       <div><label className="h3" >Client</label></div>
                        <div><label>{payment.party_name}</label></div>


                        <div><label className="h3" >Payment Method</label></div>
                        <div><label>{payment.payment_mode}</label></div>
                        
                          <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                <div><label className="h3" >Payment Date </label></div>
                                <div><label>{formatDateISO(payment.payment_date)}</label></div>
                                </div>
                                <div className="col-lg-6 col-lg-6 col-sm-12    ">
                                <div className="pull-lg-right row">
                                <div className="">
                                  <div className="h3 d-sm-block d-lg-inline" >Amount</div>
                                  <div className=" badgefontbig d-sm-block d-md-inline "> 
                                <span className="badge badgefontbig badge-label  bgsuccess"><i className="mdi mdi-circle-medium"></i>{payment.symbol} {payment.amount}</span></div></div>
                                </div> </div>
                            </div>
                            


                     
                      </div>
                      
                    </div>


                  


                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button className="me-2" variant="primary" onClick={handleCloseSelect}>
            Close
          </Button>
          <Button variant="primary" onClick={handaleEditPayment}>
            Edit
          </Button>
        </Modal.Footer> */}
      </Modal>

      {/* /Add Expenses Modal */}


      {/* Add Expenses Modal */}




      <Modal show={editSelect} onHide={handleCloseSelect}>
        <Modal.Header >
          <Modal.Title>Receipt </Modal.Title>
        </Modal.Header>
        <Modal.Body  >

          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <div className="border-0 pb-0">
                  <div className="row">


                    <div className="col-lg-12 col-md-12 col-sm-12">

                    <div className="col-lg-12 col-md-6 col-sm-12">
                      <div className="input-block mb-3">
                        <label>Payment Method<span className="alertstrik">*</span></label>
                        <Select
                          defaultValue={selectedOption1}
                          onChange={handlePamyentMethod}
                          options={options1}

                          placeholder="Select Payment Method"

                        />
                        <small className="error">{errors?.payment_mode}</small>
                      </div>
                    </div>

                      <div className="input-block mb-3">
                        <label>Amount<span className="alertstrik">*</span></label>
                        <input
 type="number"
                          className="form-control"
                          placeholder="Enter Amount"

                          onChange={handleChangeAmount}
                          id="amount"
                          name="amount"
                          maxLength={10}
                          value={payment.amount}
                          onClick={(e) => e.target.select()}
                        />
                        <small className="error">{errors?.amount}</small>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="input-block mb-3">
                        <label>Payment Date<span className="alertstrik">*</span></label>
                        <DatePicker
                          className="datetimepicker form-control"
                          selected={startInvoiceDate}
                          onChange={(date) => HandaleDate(date)}
                          maxDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                        ></DatePicker>
                        <small className="error">{errors?.payment_date}</small>
                      </div>
                    </div>


              

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>


        <Modal.Footer>
          <div className="d-flex w-100 justify-content-between">

          {(payment.id) && (
            <Button className="me-2" variant="danger" onClick={handleDeleteSelectActionConfirm}>
              <i className="far fa-trash-alt me-2" />
              Delete
            </Button>
            )}

            <div>
              <Button className="me-2" variant="primary" onClick={handleCloseEditSelect}>
                Close
              </Button>
              <Button variant="primary" onClick={handaleSavePayment}>
                Save
              </Button>
            </div>
          </div>
        </Modal.Footer>


      </Modal>



    


      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header >
          <Modal.Title>Delete Receipt </Modal.Title>
        </Modal.Header>
        <Modal.Body  >

          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <div className="border-0 pb-0">
                  <div className="row">


                    <div className="col-lg-12 col-md-12 col-sm-12">
                    {deleteact && (
                  <p>Are you sure you want to delete? </p>
                )} 
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="me-2" variant="secondprimaryary" onClick={handleCloseDelete}>
            Cancel
          </Button>

          <Button className="me-2" variant="danger" onClick={handleDeleteSelectAction}>
            Delete
          </Button>

        </Modal.Footer>
      </Modal>

      {/* /Add Expenses Modal */}



    </>
  );
};

export default ViewLedger;
