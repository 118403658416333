import React, { useState, useEffect } from "react";
import Select from "react-select";
import SettingsSidebar from "../layouts/SettingsSidebar";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import staticmessages from '../services/staticmessages';
import UserService from "../services/user.service";
import alertify from "alertifyjs";
import * as yup from 'yup';
import "../../node_modules/alertifyjs/build/css/alertify.css";

const TaxRates = () => {
  const [profile, setProfile] = useState({
    tax_label: "",
    tax_value: "",
  });

  const [isProfileLoaded, setIsProfileLoaded] = useState(false);
  const [errors, setErrors] = useState({});
  const [taxdata, setTaxdata] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await getCustomerTaxMasters();
      await fetchProfile();
    };
    fetchData();
  }, []);

  const getCustomerTaxMasters = async () => {
    try {
      const response = await UserService.getCustomerTaxMasters();
      setTaxdata(response.data);
    } catch (error) {
      console.error('Error fetching getCustomerTaxMasters:', error);
    }
  };

  const fetchProfile = async () => {
    try {
      const response = await UserService.getUserProfile();
      const { selectedtax, taxrate } = response.data;
      setProfile({
        tax_label: selectedtax || '',
        tax_value: taxrate || '',
      });
      setIsProfileLoaded(true);
    } catch (error) {
      console.error("UserService error:", error);
    }
  };

  const formattedOptions = (options) =>
    options.map((option) => ({
      value: option.value,
      label: option.label,
    }));

  const handleSelectChange = (selectedOption, name) => {
    setProfile({ ...profile, [name]: selectedOption.value });
  };


  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };

  
  const toastrError = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.error(message);
  };


  const schema = yup.object().shape({
    tax_label: yup.string().required('Tax selection is required'),



    tax_value: yup.string().when("tax_label", {
      is: (val) => {
        // This will output undefined
        console.log(val);
        return val === "ontotal";
      },
      then: (s) => s
        .required('Tax Rate is required')
        .matches(/^\d+(\.\d+)?$/, 'Tax Rate can only contain numeric characters and an optional decimal point')
        .test(
          'max-value',
          'Tax Rate must be less than or equal to 100',
          value => parseFloat(value) <= 100
        ),
      otherwise: (s) => s,
    }),



  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await schema.validate(profile, { abortEarly: false });

      const updatedProfile = {
        ...profile,
        tax_value: profile.tax_value || 0,
        taxdata: taxdata || null,
      };

      await UserService.UpdateTaxMasterSetting(updatedProfile);
      setErrors({});
      toastrSuccess(staticmessages.SETTING_TAXSUCCESS);
    } catch (validationError) {
      if (validationError instanceof yup.ValidationError) {
        const errorsObj = {};
        validationError.inner.forEach((error) => {
          errorsObj[error.path] = error.message;
        });
        setErrors(errorsObj);
      } else {

        toastrError(validationError.message);
        console.error(validationError);
      }
    }
  };

  
  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const forceMenuClose = () => {  
    setMenu(false);
  };


  return (
    <>
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={() => toggleMobileMenu()}  forceMenuClose={forceMenuClose}/>
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SettingsSidebar />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="card">
                <div className="card-body w-100">
                  <div className="content-page-header p-0">
                    <h5>{staticmessages.SETTING_TAX}</h5>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <div className="input-block mb-3">
                        <label>Tax<span className="alertstrik">*</span></label>
                        {isProfileLoaded && (
                          <Select
                            name="tax_label"
                            defaultValue={profile.tax_label ? formattedOptions(staticmessages.TAX_SETTING).find(option => option.value === profile.tax_label) : null}
                            options={formattedOptions(staticmessages.TAX_SETTING)}
                            placeholder="Select Tax Label"
                            onChange={(selectedOption) => handleSelectChange(selectedOption, 'tax_label')}
                          />
                        )}
                        <small className="error">{errors?.tax_label}</small>
                      </div>
                    </div>

                    {profile.tax_label === "ontotal" && (
                      <div className="col-lg-6 col-12">
                        <div className="input-block mb-3">
                          <label>Tax Value(%)</label>
                          <input
                            type="text"
                            name="tax_value"
                            className="form-control"
                            value={profile.tax_value}
                            maxLength={5}
                            onChange={(e) => setProfile({ ...profile, tax_value: e.target.value })}
                            onClick={(e) => e.target.select()}
                          />
                          <small className="error">{errors?.tax_value}</small>
                        </div>
                      </div>
                    )}

                    <div className="col-lg-12">
                      <div className="btn-path text-end">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 

</>

  );
};

export default TaxRates;
