

  /* eslint-disable */
import React, { useState, useEffect } from "react";
import staticmessages from '../../../services/staticmessages';
import { settingslogo1 } from '../../../_components/Imagepath';
import Select from 'react-select';
import UserService from "../../../services/user.service";
import alertify from "alertifyjs";
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

import "../../../../node_modules/alertifyjs/build/css/alertify.css";
import "../../../../node_modules/alertifyjs/build/css/themes/semantic.css";
import config from '../../../services/config';
import Resizer from 'react-image-file-resizer'; // Import the image resizer
import { BillwizerLogoLight } from "../../../_components/Imagepath";

  const Step3Form = () => {
    const [logoUrl, setLogoUrl] = useState(null);

    const history = useHistory();
  const [errors, setErrors] = useState({});
  // Load invoice from localStorage
  const loadInvoiceFromStorage = () => {
    const storedInvoice = localStorage.getItem('invoicewizard');
    return storedInvoice ? JSON.parse(storedInvoice) : null;
  };

  const [invoice, setInvoice] = useState(() => {
    const storedInvoice = loadInvoiceFromStorage();
    return storedInvoice && Object.keys(storedInvoice).length > 0 ? storedInvoice : {};
  });

  useEffect(() => {
    if (invoice) {
      localStorage.setItem('invoicewizard', JSON.stringify(invoice));
    }
  }, [invoice]);

  const handlePrevious = () => {
    history.push('step2form');
  };

  const [step3Data, setStep3Data] = useState({
    selectedFile: null,
    logoUrl: "",
    logo_image:"",
  
  });

  const step3Schema = yup.object().shape({
    selectedFile: yup
      .mixed()
      .required("Please select an image")
      .test(
        "fileFormat",
        "Unsupported format. Only JPG and PNG files are allowed.",
        (value) => {
          // If there's no file, return true because it's required.
          if (!value) return false;

          // Check the file type by accessing the file's MIME type.
          const supportedFormats = ["image/jpeg", "image/png"];
          return value && supportedFormats.includes(value.type);
        }
      ),
  });

  // const step3Schema = yup.object().shape({
  //   selectedFile: yup.string().when("logoUrl", {
  //     is: (logoUrl) => {
  //       // This will output undefined
  //       console.log("logoUrl"+logoUrl);
  //       return true;
  //     },
    
  //     then: (s) =>
  //       s
  //         .required("Please select an image"),
  //         // .test(
  //         //   "file-type",
  //         //   "Only PNG, JPG images are allowed",
  //         //   (value) => {
  //         //     const fileExtension = value ? value.split(".").pop().toLowerCase() : "";
  //         //     return ["png", "jpg", "jpeg"].includes(fileExtension);
  //         //   }
  //         // ),
  //     otherwise: (s) => s.nullable(),
  //   }),
  // });
  

  
  


  const validateStep = async () => {
    alertify.set("notifier", "position", "top-center");
    const data = { selectedFile };
    try {
      await step3Schema.validate(data, { abortEarly: false });
      setErrors({});
      return data; // Return validated data
    } catch (err) {
      const newErrors = {};
      err.inner.forEach((error) => {
        newErrors[error.path] = error.message;
        alertify.error(error.message);
      });
      setErrors(newErrors);
      return null;
    }
  };

  const handleNextNext =  () => {

    history.push('step4form');

  };

  const SkipPrevious =  () => {

    history.push('step4form');

  };


  const handleNext = async () => {
    const validatedData = await validateStep();

    if (validatedData) {
      try {
        let response;
        let tempresponse;

        tempresponse = await UserService.uploadLogo(validatedData.selectedFile);
        response = tempresponse.data.company_logo ? {"id":1} : "";


        



        console.log("API Error:", response);

        if (response && response.id) {
          
          alertify.set("notifier", "position", "top-center");
          alertify.success("Logo Uploaded.");
         
          
         history.push('step4form');


        }
        else if (tempresponse &&  tempresponse.errorMessage) {
          // Handle error message from server
          throw new Error(tempresponse.errorMessage);
        }
        else {
          throw new Error("Unexpected response from server.");
        }
      } catch (error) {
        alertify.set("notifier", "position", "top-center");
        alertify.error(error.message || "An error occurred while uploading the logo.");
        console.error("API Error:", error); // Log error details for debugging
      }
    } else {
      alertify.set("notifier", "position", "top-center");
      alertify.error("Please fill all the required fields to proceed.");
    }
  };
  


    const API_LOGOURL = config.API_LOGOURL;

    const [selectedFile, setSelectedFile] = useState(null);
    // eslint-disable-next-line no-unused-vars
   

    useEffect(() => {
      fetchLogo();
    }, []);

    useEffect(() => {
      console.log("Updated step3Data in Step2Form:", step3Data);
    }, [step3Data]);
    // Fetch the existing logo URL when the component mounts
    const fetchLogo = async () => {
      try {
        const response = await UserService.getUserProfile();

        console.error('Error fetching getUserProfile:', response);


        if (response.data.company_logo) {
          setLogoUrl(API_LOGOURL + response.data.company_logo);

          setStep3Data(prevState => ({
            ...prevState,
            ["logoUrl"]:  API_LOGOURL + response.data.company_logo,
          }));

          
        }
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };

   
    // eslint-disable-next-line no-unused-vars
    // const toastrSuccess = (message) => {
    //   alertify.set("notifier", "position", "top-center");
    //   alertify.success(message);
    // };

      // eslint-disable-next-line no-unused-vars
    const handleSubmit = async (event) => {
      event.preventDefault();
      if (!selectedFile) {
        alert('Please select a file first!');
        return;
      }

      try {
        const response = await UserService.Step3Form(selectedFile);
        console.log('File uploaded successfully 222:', response.data.company_logo);
        // Update the logo URL to display the newly uploaded logo
        localStorage.setItem('profileImage', response.data.company_logo);
        setLogoUrl(API_LOGOURL + response.data.company_logo);
        window.location.reload();
      } catch (error) {
        console.error('Error uploading file:', error);
        // Handle error appropriately
      }
    };
    const validateImage = (file) => {
      const supportedFormats = ["image/jpeg", "image/png"];
      const maxFileSize = 5 * 1024 * 1024; // 2MB
  
      if (!supportedFormats.includes(file.type)) {
        alertify.set("notifier", "position", "top-center");
        alertify.error("Unsupported format. Only JPG and PNG files are allowed.");
        return false;
      }
      if (file.size > maxFileSize) {
        alertify.set("notifier", "position", "top-center");
        alertify.error("File size exceeds 2MB limit.");
        return false;
      }
      return true;
    };

 // eslint-disable-next-line no-unused-vars
 const handleFileChange = (event) => {

  const file = event.target.files[0];


  if (file) {
    // Validate the file type and size
    if (!validateImage(file)) {
      setSelectedFile(null); // Clear selected file if validation fails
      return; // Stop further processing
    }
  if (file) {
    // Resize the image before uploading
    Resizer.imageFileResizer(
      file,
      500, // Max width
      500, // Max height
      'JPEG', // Output format
      70, // Quality (0-100)
      0, // Rotation
      (uri) => {
        const resizedFile = new File([uri], file.name, { type: file.type });
       

      
        const fileUrl = URL.createObjectURL(resizedFile);
        setLogoUrl(fileUrl);
      
        setSelectedFile(resizedFile);
      
        handleSelectChange("logo_image","logo_image");
        handleSelectChange(resizedFile,"selectedFile");


      },
      'blob' // Output type
    );
  }

 
}




};


    const handleSelectChange = (selectedOption, name) => {
  
      setStep3Data(prevState => ({
        ...prevState,
        [name]:  selectedOption || '',
      }));

    };

    return (
      <>
 <div className="main-wrapper container d-flex align-items-center justify-content-center boardingwizard" style={{ minHeight: "80vh" }}>
    <div className="content container-fluid">
    <img
          className="img-fluid logo-dark mb-2"
          src={BillwizerLogoLight}
          alt="Logo"
        />
    <div className="row justify-content-center">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="card">
            <div className="card-header">
            
            </div>
          
            <div className="card-body">
            

         

              <div className="row">
              
                <div className="col-xl-12 col-md-12">
                  <div className="card company-settings-new">
                    <div className="card-body w-100">
                      <div className="content-page-header">
                        <h5>{staticmessages.SETTING_step3Data}</h5>
                      </div>
                      <div className="row">

                        <div className="col-lg-12 col-12">
                          <div className="input-block mb-3">
                            
                            <div className="input-block service-upload logo-upload mb-0">
                              <div className="drag-drop">
                                <h6 className="drop-browse align-center">
                                  <span className="text-info me-1">Click To Replace</span>{" "}
                                  or Drag and Drop
                                </h6>
                                <p className="text-muted">
                                  PNG, JPG (Max 500*500px)
                                </p>
                                <input type="file"
                                  id="fileInput"
                                  onChange={handleFileChange}
                                />
                              </div>
                              <span className="sites-logo">
                                {logoUrl ? (
                                  <div className="mt-4">

                                    <img src={logoUrl} alt="Company Logo" className="img-fluid" />
                                  </div>
                                ) : (
                                  <img src={settingslogo1} alt="upload" />
                                )}

 
                              </span>
                            </div>
                            {errors.selectedFile && <small className="error">{errors.selectedFile}</small>}
                          </div>
                        </div>





                      </div>
                    </div>
                  </div>
                </div>

                
<div className="d-flex justify-content-center">
                     
                     <button type="button" className="btn btn-primary me-2" onClick={handlePrevious}>
                       Back
                     </button>

                   

                     {step3Data.logoUrl ? (
                                   <button className="btn btn-primary" type="button" onClick={handleNextNext}>
                                   Next 
                                 </button>
                                ) : (
                                  <>
                                  <button className="btn btn-primary me-2" type="button" onClick={handleNext}>
                     Save
                   </button>

<button type="button" className="btn btn-primary " onClick={SkipPrevious}>
Skip
</button></>
                                )}
                  
                    


                 </div>

              </div>



           </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      </>
    )
  }



  export default Step3Form;

