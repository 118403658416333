import React, { useState, useEffect , useRef} from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from "react-router-dom";
import AuthService from "../services/auth.service";
import {

  avatar07,

  logo,
  logoSmall,
  logoSmallWhite,
  search,
} from "../_components/Imagepath";

const Header = ({ onMenuClick,forceMenuClose ,onApplyFilter , searchTerm }) => {
  const [userName, setUserName] = useState("");





  useEffect(() => {
    const getUser = async () => {
      try {
        let res = await AuthService.getCurrentUser();
        console.error('Login failed:', res);

        setUserName(res.name);
      } catch (error) {
        console.error('Login failed:', error);
      }
    };

    getUser();

   
  }, []);

  const handleSearchTop = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    onApplyFilter(searchTerm); // Call parent's filter function
  };

  const history = useHistory();
  const Logout = () => {
  
  
      // Clear token from localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      // Redirect to login page
      history.push("/login");
   
  };


  const menuButtonRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
    
      if (menuButtonRef.current && !menuButtonRef.current.contains(event.target)) {
        // Handle the case where the click was outside the menu button
        console.log("Click outside the menu button detected");
        forceMenuClose();
      }
      
    };

    // Attach a global click event listener
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const cachedImage = localStorage.getItem('profileImage');


  return (
    <div className="header header-one">
      <Link
        to="index"
        className="d-inline-flex d-sm-inline-flex align-items-center d-md-inline-flex d-lg-none align-items-center device-logo"
      >
        <img src={logo} className="img-fluid logo2 logoheadtop" alt="Logo" />
      </Link>
      <div className="main-logo d-inline float-start d-lg-flex align-items-center d-none d-sm-none d-md-none">
        <div className="logo-white">
          <Link to="index">
            <img src={logo} className="img-fluid logo-blue" alt="Logo" />
          </Link>
          <Link to="index">
            <img
              src={logoSmallWhite}
              className="img-fluid logo-small"
              alt="Logo"
            />
          </Link>
        </div>
        <div className="logo-color">
          <Link to="index">
            <img src={logo} className="img-fluid logo-blue" alt="Logo" />
          </Link>
          <Link to="index">
            <img src={logoSmall} className="img-fluid logo-small" alt="Logo" />
          </Link>
        </div>
      </div>
    
      {/* Search */}
      <div className="top-nav-search">
        <form>
          <input
            type="text"
            className="form-control"
            placeholder="Search here"
            onChange={handleSearchTop}
            value={searchTerm}

          />
          <button className="btn" type="submit">
            <img src={search} alt="img" />
          </button>
        </form>
      </div>
      {/* /Search */}
      {/* Mobile Menu Toggle */}
      <Link
        to="#"
        className="mobile_btn"
        id="mobile_btn"
        onClick={onMenuClick}
        ref={menuButtonRef}
      >
        <i className="fas fa-bars" />
      </Link>
      {/* /Mobile Menu Toggle */}
      {/* Header Menu */}
      <ul className="nav nav-tabs user-menu">
     
       
        {/* User Menu */}
        <li className="nav-item dropdown">
          <Link
            to="#"
            className="user-link nav-link"
            data-bs-toggle="dropdown"
          >
            <span className="user-img">
            {cachedImage ? (
        <img src={cachedImage} alt="User Logo" className="profilesidebar" />
      ) : (
        <img src={avatar07} alt="User Logo" className="profilesidebar" />
      )}
              <span className="animate-circle" />
            </span>
            <span className="user-content">
              <span className="user-details">Admin</span>
              <span className="user-name">{userName}</span>
            </span>
          </Link>
          <div className="dropdown-menu menu-drop-user">
            <div className="profilemenu">
              <div className="subscription-menu">
                <ul>
                  <li>
                    <Link className="dropdown-item" to="/settings">
                      Settings
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="subscription-logout">
                <ul>
                  <li className="pb-0">
                    
                    <Link className="dropdown-item" to="#" onClick={Logout}>
                      Log Out
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        {/* /User Menu */}
      </ul>
      {/* /Header Menu */}
    </div>
  );
};

Header.propTypes = {
  onMenuClick: PropTypes.func.isRequired,
  forceMenuClose: PropTypes.func.isRequired,
  onApplyFilter: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

export default Header;
