// eslint-disable-next-line no-unused-vars
import moment from 'moment-timezone';

export const convertPercentageDiscount = (total, flatDiscount) => {
    if (total === 0) {
      console.error("Total amount cannot be zero.");
      return 0;
    }
    return (flatDiscount / total) * 100;
  };



  export const calculateDiscountOnPercentage = (itemAmt, discount) => {
    const discountAmt = itemAmt * (discount / 100);
    return discountAmt;
  };
  

  export const formattedWithFractionLengthDouble = (num, fractionLength, defcountry, country) => {
    const adjustedFractionLength = defcountry.toLowerCase() === country.toLowerCase() ? fractionLength : 2;
    const roundedValue = num.toFixed(adjustedFractionLength);
    const numFormattedString = roundedValue.toString(); // Converts to string with fixed decimal places
    const numWithoutCommas = numFormattedString.replace(/,/g, ''); // Remove commas if present
    
    return parseFloat(numWithoutCommas) || 0.0;
  };


  export const formattedWithFractionTwoDouble = (num, fractionLength) => {
   
    const roundedValue = num.toFixed(fractionLength);
    const numFormattedString = roundedValue.toString(); // Converts to string with fixed decimal places
    const numWithoutCommas = numFormattedString.replace(/,/g, ''); // Remove commas if present
    
    return parseFloat(numWithoutCommas) || 0.0;
  };

  // eslint-disable-next-line no-unused-vars
 // eslint-disable-next-line no-unused-vars
export const renderTaxData = (
  taxdata,
  currformat,
  invoice,
  defcountry,
) => {
  console.log("Helper333 ----------"+JSON.stringify(invoice, null, 2));


  console.log("----selectedinvoice------++");
  console.log(invoice);
  console.log("----selectedinvoice------");


  // console.log(
  //   invoice.selectedtax+" testrenderTaxDatalocal 111 FFF 000" + JSON.stringify(taxdata, null, 2)
  // );

  let taxarray = invoice.edittaxdata || invoice.taxdata; // Ensure taxdata is an array

  if (invoice) {
    const globaltax = invoice.globaltax;


if(invoice.id)
{
           // Case: Different country
    if (invoice.country.toLowerCase() !== defcountry.toLowerCase()) {
      console.log("testrenderTaxDatalocal Helper333 ----------Other Country");
     

      let edittax = invoice.edittaxdata;

    
      taxarray = edittax.map((item) => {
        const fitem = Array.isArray(item) ? item[0] : item;
        let fetchlabel = fitem.taxLabel ? fitem.taxLabel : fitem.tax_label;

        console.log(" testrenderTaxDatalocal MMM == " +JSON.stringify(taxarray, null, 2));

        let disptext = "";
        let tax_value = 0;
        let taxAmount = 0;


        console.log("testrenderTaxDatalocal"
          +
          fetchlabel+"----"+fitem.tax_label+"  111 FFF 111" + JSON.stringify(fitem, null, 2)
        );
  
        
        if (invoice.selectedtax === "ontotal") {
          disptext = `${fetchlabel ? fetchlabel : fitem.tax_label} (${invoice.invoice_taxrate / taxarray.length}%)`;
          taxAmount = globaltax / taxarray.length;
          tax_value = invoice.invoice_taxrate / taxarray.length;
        } else {
          disptext = `${fetchlabel}`;
          tax_value = globaltax / taxarray.length;
          taxAmount = globaltax / taxarray.length;
        }
  

  

        return {
          ...fitem,
          taxValue: taxAmount,
          labelDiplay: disptext,
         
          tax_value: tax_value,
          taxAmount: taxAmount,
          taxLabel: fitem.taxLabel,
        };
      });

    // Case: Same country
    } else if (invoice.customer) {
      const isSameState = invoice.customer.state.toLowerCase() === invoice.state.toLowerCase();

          



      if (isSameState) {
        // Remove ID 3 if customer state matches invoice state
        if(taxarray.length == 3)
        {
          taxarray = taxarray.filter((item) => item.id !== 3);
        }
        
        
      } else {
        // Remove IDs 1 and 2 otherwise
        if(taxarray.length == 3)
          {
        taxarray = taxarray.filter((item) => ![1, 2].includes(item.id));
          }
      }

   

      
      taxarray = taxarray.map((item) => {
        const fitem = Array.isArray(item) ? item[0] : item;
        let fetchlabel = fitem.taxLabel ? fitem.taxLabel : fitem.tax_label;

        let disptext = "";
        let tax_value = 0;
        let taxAmount = 0;


        console.log("testrenderTaxDatalocal"
          +
          fetchlabel+"----"+fitem.tax_label+"  111 FFF 111" + JSON.stringify(fitem, null, 2)
        );
  
  
        
        if (invoice.selectedtax === "ontotal") {
          disptext = `${fetchlabel ? fetchlabel : fitem.tax_label} (${invoice.invoice_taxrate / taxarray.length}%)`;
          taxAmount = globaltax / taxarray.length;
          tax_value = invoice.invoice_taxrate / taxarray.length;
        } else {
          disptext = `${fetchlabel}`;
          tax_value = globaltax / taxarray.length;
          taxAmount = globaltax / taxarray.length;
        }
  

        // let fetchlabel = fitem.taxLabel ? fitem.taxLabel : fitem.item_label;

        // const disptext = invoice.selectedtax === "ontotal"
        //   ? `${fetchlabel ? fetchlabel : fitem.tax_label} (${invoice.invoice_taxrate / taxarray.length}%)`
        //   : `${fitem.tax_label}`;

        // const tax_value = globaltax / taxarray.length;
        // const taxAmount = invoice.selectedtax === "ontotal"
        //   ? invoice.invoice_taxrate / taxarray.length
        //   : globaltax / taxarray.length;

        return {
          ...fitem,
          taxValue: taxAmount,
          labelDiplay: disptext,
         
          tax_value: tax_value,
          taxAmount: taxAmount,
          taxLabel: fitem.taxLabel,
        };
      });
    }
}
else
{


    // Case: Different country
    if (invoice.country.toLowerCase() !== defcountry.toLowerCase()) {
      console.log("testrenderTaxDatalocal Helper333 ----------Other Country");

    

      taxarray = taxarray.map((item) => {
        const fitem = Array.isArray(item) ? item[0] : item;
        let fetchlabel = fitem.taxLabel ? fitem.taxLabel : fitem.tax_label;

        let disptext = "";
        let tax_value = 0;
        let taxAmount = 0;


        console.log("testrenderTaxDatalocal"
          +
          fetchlabel+"----"+fitem.tax_label+"  111 FFF 111" + JSON.stringify(fitem, null, 2)
        );
  
        
        if (invoice.selectedtax === "ontotal") {
          disptext = `${fetchlabel ? fetchlabel : fitem.tax_label} (${invoice.invoice_taxrate / taxarray.length}%)`;
          taxAmount = globaltax / taxarray.length;
          tax_value = invoice.invoice_taxrate / taxarray.length;
        } else {
          disptext = `${fetchlabel}`;
          tax_value = globaltax / taxarray.length;
          taxAmount = globaltax / taxarray.length;
        }
  

  

        return {
          ...fitem,
          taxValue: taxAmount,
          labelDiplay: disptext,
         
          tax_value: tax_value,
          taxAmount: taxAmount,
          taxLabel: fitem.taxLabel,
        };
      });

    // Case: Same country
    } else if (invoice.customer) {
      const isSameState = invoice.customer.state.toLowerCase() === invoice.state.toLowerCase();

         

      if (isSameState) {
        // Remove ID 3 if customer state matches invoice state
        if(taxarray.length == 3)
        {
          taxarray = taxarray.filter((item) => item.id !== 3);
        }
        
        
      } else {
        // Remove IDs 1 and 2 otherwise
        if(taxarray.length == 3)
          {
        taxarray = taxarray.filter((item) => ![1, 2].includes(item.id));
          }
      }

    
   

      
      taxarray = taxarray.map((item) => {
        const fitem = Array.isArray(item) ? item[0] : item;
        let fetchlabel = fitem.taxLabel ? fitem.taxLabel : fitem.tax_label;

        let disptext = "";
        let tax_value = 0;
        let taxAmount = 0;


        console.log("testrenderTaxDatalocal"
          +
          fetchlabel+"----"+fitem.tax_label+"  111 FFF 111" + JSON.stringify(fitem, null, 2)
        );
  
  
        
        if (invoice.selectedtax === "ontotal") {
          disptext = `${fetchlabel ? fetchlabel : fitem.tax_label} (${invoice.invoice_taxrate / taxarray.length}%)`;
          taxAmount = globaltax / taxarray.length;
          tax_value = invoice.invoice_taxrate / taxarray.length;
        } else {
          disptext = `${fetchlabel}`;
          tax_value = globaltax / taxarray.length;
          taxAmount = globaltax / taxarray.length;
        }
  

        // let fetchlabel = fitem.taxLabel ? fitem.taxLabel : fitem.item_label;

        // const disptext = invoice.selectedtax === "ontotal"
        //   ? `${fetchlabel ? fetchlabel : fitem.tax_label} (${invoice.invoice_taxrate / taxarray.length}%)`
        //   : `${fitem.tax_label}`;

        // const tax_value = globaltax / taxarray.length;
        // const taxAmount = invoice.selectedtax === "ontotal"
        //   ? invoice.invoice_taxrate / taxarray.length
        //   : globaltax / taxarray.length;

        return {
          ...fitem,
          taxValue: taxAmount,
          labelDiplay: disptext,
         
          tax_value: tax_value,
          taxAmount: taxAmount,
          taxLabel: fitem.taxLabel,
        };
      });
    }
  }

}
 

  console.log(taxarray);

  return taxarray;
};


  
  export const returnDataValue = (data, givenmachdata, returnlabel) => {
    const selectedOptiontax = data.find(option => option.value === givenmachdata);
    
    return selectedOptiontax ? selectedOptiontax[returnlabel] : null;
  };
  

  export const formatDateISO_old = (isoDateString) => {
    const date = new Date(isoDateString);
    const day = date.getUTCDate().toString().padStart(2, '0'); // Get day and pad with leading zero if necessary
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Get month (adding 1 to convert to 1-based index) and pad with leading zero if necessary
    const year = date.getUTCFullYear();
  
    return `${day}-${month}-${year}`;
  };

  export const formatDateISO = (timestamp) => {
    // Add one day in milliseconds to the timestamp
    let date = new Date(timestamp + 24 * 60 * 60 * 1000);
    
    // Format the date in UTC timezone
    return moment.tz(date, 'UTC').format('DD-MM-YYYY');
  };

  export const formatDateISONEW = (timestamp) => {
    // Add one day in milliseconds to the timestamp
    //let date = new Date(timestamp + 24 * 60 * 60 * 1000);
    return timestamp.toISOString().split('T')[0];
    // Format the date in UTC timezone
   // return moment.tz(date, 'UTC').format('YYYY-MM-DD');
  };


  export const getCurrentDateISO = () => {
    const currentDate = new Date();
    return currentDate; // Get YYYY-MM-DD from ISO string
  };
  export const getExtendDateISO = () => {
    const currentDate = new Date();
    const extendedDate = new Date(currentDate.getTime() + (30 * 24 * 60 * 60 * 1000)); // Add 30 days in milliseconds
    return extendedDate; // Get YYYY-MM-DD from ISO string
  };




  export const PaymentTerms = [
    { value: 'DUE_ON_RECEIPT', label: 'Due on Receipt' },
    { value: 'NET_15', label: 'Net 15' },
    { value: 'NET_30', label: 'Net 30' },
    { value: 'NET_45', label: 'Net 45' },
    { value: 'NET_60', label: 'Net 60' },
    { value: 'CUSTOM_DATE', label: 'Custom Date' },
  ];

  // export const PaymentTerms = {
  //   DUE_ON_RECEIPT: "Due on Receipt",
  //   NET_15: "Net 15",
  //   NET_30: "Net 30",
  //   NET_45: "Net 45",
  //   NET_60: "Net 60",
  //   CUSTOM_DATE: "Custom Date",
    
  // };
  

  export const SubscriptionTerms = [
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Quarterly', label: 'Quarterly' },
    { value: 'Half Yearly', label: 'Half Yearly' },
    { value: 'Yearly', label: 'Yearly' },
    { value: 'Custom Date', label: 'Custom Date' },
  ];
 
  



    // eslint-disable-next-line no-unused-vars
    export const calculateTaxableAmountPerItem = (items, discountAmount) => {

     // console.log("calculateTaxableAmountPerItem 666 222----"+discountAmount);
    

      return items.reduce((total, item) => {

        if(discountAmount > 0)
        {
          return total + item.totalprice;
        }
        else if (item.taxrate > 0) {
          return total + item.totalprice;
        }
        return total;
      }, 0);
    };