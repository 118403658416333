import React, { useState ,useEffect } from "react";
import Header from "../layouts/Header";

// eslint-disable-next-line no-unused-vars
import Sidebar from "../layouts/Sidebar";
  // eslint-disable-next-line no-unused-vars
import ReportModal from "../common/modal/reportModal";
// eslint-disable-next-line no-unused-vars
import BreadcrumbsReportPayment from "../common/breadcrumb/breadCrumbsReportPayment";
import { Table } from "antd";

  // eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
import {formatDateISO, getCurrentDateISO, getExtendDateISO} from '../services/Helper';



  // eslint-disable-next-line no-unused-vars
import report from '../services/reports.service';
  // eslint-disable-next-line no-unused-vars
import staticmessages from '../services/staticmessages';

import ClientService from '../services/client.service';

import Modal from 'react-bootstrap/Modal';
  // eslint-disable-next-line no-unused-vars
import * as yup from "yup";
  // eslint-disable-next-line no-unused-vars
import alertify from "alertifyjs";
import "../../node_modules/alertifyjs/build/css/alertify.css";
import "../../node_modules/alertifyjs/build/css/themes/semantic.css";
import InvoiceService from '../services/invoice.service';
import Button from 'react-bootstrap/Button';
import Select from "react-select";
// eslint-disable-next-line no-unused-vars
import DatePicker from "react-datepicker";

  // eslint-disable-next-line no-unused-vars
  import Spinners from '../components/spinners';

  
const TwentySixAS = () => {

  
/// Model PAyment Start
  // eslint-disable-next-line no-unused-vars
  const [customtitle, setCustomTitle] = useState(staticmessages.PAYMENT_LIST_TITLE);
let currentdatepr = new Date();


const handleCloseSelect = () => {
  setErrors({});
  setShowPayment(false);
  setShowSelect(false);

};

const [showPayment, setShowPayment] = useState(false);
// eslint-disable-next-line no-unused-vars
const [payment, setPaymentData] = useState({
  client_id: "", linkModeType: "invoicelist", payment_date: currentdatepr,
  invoice_id: "",
  uniqueno: "",
  dateformat: "",
  symbol: "",
  paymentModeType: "invoicepayment",
  amount: "",
  newamount: "",
  payment_method: staticmessages.PAYMENT_SETTINGTDS[0].value,
  id: "",

});
// eslint-disable-next-line no-unused-vars
const openPaymentForm = () => {
  setShowPayment(true);
  setPaymentData((prev) => ({
    ...prev,
   
    invoice_id: "",
    uniqueno: "",
   
    paymentModeType: "invoicepayment",
    amount: "",
    newamount: "",
    payment_method: staticmessages.PAYMENT_SETTINGTDS[0].value,
    id: "",

  }));

  setSelectedOption1(null);
  setSelectedClient(null);

};
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState({}); // State to hold validation errors

    // eslint-disable-next-line no-unused-vars
    const [startInvoiceDate, setStartInvoiceDate] = useState(new Date());

  // eslint-disable-next-line no-unused-vars
  const [selectedOption1, setSelectedOption1] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const handlePamyentMethod = (value) => {
    setSelectedOption1(value);

    setPaymentData((prev) => ({
      ...prev,
      payment_method: value.value
    }));

  };
    // eslint-disable-next-line no-unused-vars
    const handleChangeAmount = (e) => {
      const { value } = e.target;
  
      setPaymentData((prev) => ({
        ...prev,
        amount: value,
        newamount: value,
      }));
  
    };
  // eslint-disable-next-line no-unused-vars
  const HandaleDate = (value) => {

    console.log(value);
    setStartInvoiceDate(value);
    setPaymentData((prev) => ({
      ...prev,
      payment_date: value
    }));

  };
    // eslint-disable-next-line no-unused-vars
    const [selectedClientDetails, setSelectedClientDetails] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [selectedClient, setSelectedClient] = useState(null);
  const handleCustomerChange = (selectedOption) => {

 

    if (clients.length > 0) {
      // Find client with id 439
      const findclient = clients.find(client => client.id === selectedOption.value);

      console.log(findclient);


      if (findclient) {
        setSelectedClientDetails(findclient);
        setPaymentData((prev) => ({
          ...prev,
          client_id: findclient.id
        }));
     

        //setInvoice({ ...invoice, customer: findclient , client_name: selectedOption.label });
      }
    }



    setSelectedClient(selectedOption);
    // Additional logic if needed
  };

  // eslint-disable-next-line no-unused-vars
  const handaleSavePayment = () => {
   

    if (startInvoiceDate instanceof Date) {
      console.log("------handaleSavePayment aaa ===== " + startInvoiceDate);
      // Check if the date is already in the desired format


      setPaymentData(prevState => ({
        ...prevState,
        ...payment, // Spread the properties of record into the state
        payment_date: startInvoiceDate,
        paymentModeType: "tdspayment",
       
      }));

      setCallApi(new Date());
    } else if (typeof startInvoiceDate === 'number') {
      // Convert timestamp to Date and format
      const date = new Date(startInvoiceDate);
      const formattedDate = date.toISOString();
      setPaymentData(prevState => ({
        ...prevState,
        ...payment, // Spread the properties of record into the state
        payment_date: formattedDate,
      
      }));
      setCallApi(new Date());
    }
  };


  const getUser = async () => {
    try {
      let res = await InvoiceService.getUserProfile();



   

      setPaymentData(prevState => ({
        ...prevState,
      
        symbol: res.data.symbol,
        dateformat: res.data.dateformat,
      
      }));



      console.log("getUser=======");
      console.log(res.data.symbol);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [callapi, setCallApi] = useState(null);

  useEffect(() => {
    if (callapi) {
      console.log("------handaleSavePaymentFianlCall zzzz ===== " + startInvoiceDate + "--" + payment.payment_date);
      handaleSavePaymentFianlCall()
    }

  }, [callapi]); // 

  // eslint-disable-next-line no-unused-vars
  const handaleSavePaymentFianlCall = () => {

    console.log("------handaleSavePaymentFianlCall aaa ===== " + JSON.stringify(payment, null, 2));

    schema.validate(payment, { abortEarly: false })
      .then(() => {
        const action = payment.id ? InvoiceService.updatePaymentcustomer : InvoiceService.addPaymentcustomer;


        console.log("------handaleSavePayment ===== " + action);

        console.log(payment);

        action(payment.id, payment, listType)
          .then(() => {
            setCallApi(null);

            if (payment.id) {
              let mess = `${customtitle} ${staticmessages.PAYMENT_USUCCESS}`;
              toastrSuccess(mess);
             // getLedgerData(filteroption);
              window.location.reload();

            }
            else {
              let mess = `${customtitle} ${staticmessages.PAYMENT_CSUCCESS}`;
              toastrSuccess(mess)
             // getLedgerData(filteroption);
              window.location.reload();
            }

            // if (targetButtonRef.current) {
            //   targetButtonRef.current.click();
            // }


          })
          .catch((error) => {
            setError(error.message);
            toastrError(error.message);
          });
      })
      .catch((validationErrors) => {
        // Yup validation errors
        const errorsObj = {};
        validationErrors.inner.forEach((error) => {
          errorsObj[error.path] = error.message;
        });
        setErrors(errorsObj);
      });
  };

  const schema = yup.object({
    amount: yup.string()
    .matches(/^\d+(\.\d+)?$/, 'Amount can only contain numeric characters and an optional decimal point')
    .required('Amount is required')
    .test('is-positive', 'Amount must be greater than 0', value => {
      const numberValue = parseFloat(value);
      return !isNaN(numberValue) && numberValue > 0;
    }),
    client_id: yup.string().required("Client is required"),
    payment_method: yup.string().required("Payment Method is required"),
    payment_date: yup.string().required("Payment Date is required"),
  });

  const toastrError = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.error(message);
  };
  const toastrSuccess = (message) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(message);
  };

/// Model PAyment Start End







  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
const paginationSize = 10;
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);

    // eslint-disable-next-line no-unused-vars
  const [datasource, setInvoices] = useState([]);
    // eslint-disable-next-line no-unused-vars
  const [filteredDataSource, setFilteredDataSource] = useState([]);

    // eslint-disable-next-line no-unused-vars
  const [listType, setListType] = useState("invoicelist");

  //       <ReportModal setShow={setShow} show={show} customtitle={customtitle} listType = {listType} fdate = {fdate} tdate = {tdate} />

    // eslint-disable-next-line no-unused-vars

  let currentdate =   new Date();
  let currentdateex =  new Date(currentdate.getTime() - (30 * 24 * 60 * 60 * 1000));

  const [filteroption, setFilterOptions] = useState({searchterm: "",fdate: currentdateex,tdate:currentdate});


  // eslint-disable-next-line no-unused-vars
const [loading, setLoading] = useState(true); // Loading state

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const formattedOptionsClients = (options) =>
    options.map((option) => ({
      value: option.id,
      label: option.client_name,
    }));
  const [clients, setClients] = useState([]);

  useEffect(() => {
    
      getTwentySixAS();
      fetchClients(); 
   
  }, [filteroption]);

  const fetchClients = async () => {
    try {
      const response = await ClientService.getClients();
      setClients(response.data);

    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const getTwentySixAS = async () => {

   

    report.getTwentySixASReport(listType, filteroption)
      .then((response) => {
        // setInvoices(response.data);
        // setFilteredDataSource(response.data);

        console.log(response);

        setInvoices(response.data);
        setFilteredDataSource(response.data);
        setLoading(false);

      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleApplyFilter = (searchTerm) => {
    console.log("handleApplyFilter ---"+searchTerm);
    setShow(false);
    console.log(searchTerm);
    setSearchTerm(searchTerm);
    setFilterOptions((prev) => ({
      ...prev,
      searchterm: searchTerm
    }));
 
  getTwentySixAS(); // Reload parent data
  };

  const handleApplyResetApply = () => {
    console.log("handleApplyResetApply");
    setFilterOptions((prev) => ({
      ...prev,
      searchterm: "",fdate: currentdate,tdate:currentdateex
    }));

   
    setShow(false);
    getTwentySixAS(); // Reload parent data
  };

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };













  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text, record, index) => (
        <>
        <div className="table-invoice  center-center ">
          {(page - 1) * paginationSize + index + 1}
        </div>

        <div className="table-invoice  center-center mobile-only">
<div className="dropdown dropdown-action">
  <Link
    to="#"
    className=" btn-action-icon "
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <i className="fas fa-ellipsis-v" /> 
  </Link>
  <div className="dropdown-menu dropdown-menu-right">
    <ul>
      <li>
      <button className="dropdown-item" onClick={() => handaleEditPaymentnew(record)}>
      
          <i className="far fa-edit me-2" />
          Edit
        </button>
      </li>
      <li>

        <Link
          className="dropdown-item"
          to="#"
   
          onClick={() => handleDeleteSelectActionConfirmnew(record)}>
          <i className="far fa-trash-alt me-2" />
          Delete
        </Link>
      </li>
    </ul>
  </div>
</div>
</div>
</>

      ),
    },

    {
      title: "Client",
      dataIndex: "client_name",
      render: (text, record) => (
        <>
          <span className="blue-text-link" onClick={() => handleRowClick(record)}>{record.client_name}</span>
        </>
      ),
      sorter: (a, b) => {
        // Make sure both a and b have client_name defined
        if (!a.client_name) return 1;
        if (!b.client_name) return -1;
        
        // Handle sorting based on client_name, assuming it is a string
        return a.party_name.localeCompare(b.party_name);
      },
    },

  
   
    {
      title: "Date",
      dataIndex: "payment_date",
      render: (text, record) => (
        <>
         
         <span className="blue-text-link" onClick={() => handleRowClick(record)}>{formatDateISO(record.payment_date)}</span>

            
        </> ),
      sorter: (a, b) => a.payment_date.length - b.payment_date.length,
    },
   
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text, record) => (
        <>
         
         <span className="blue-text-link" onClick={() => handleRowClick(record)}> {record.symbol}{record.amount}</span>
            
        </>
         ),
      sorter: (a, b) => a.amount.length - b.amount.length,
    },

    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <div className="d-flex align-items-center">
          <div className="dropdown dropdown-action">
            <Link
              to="#"
              className=" btn-action-icon "
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v" /> 
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <ul>
                <li>
                <button className="dropdown-item" onClick={() => handaleEditPaymentnew(record)}>
                
                    <i className="far fa-edit me-2" />
                    Edit
                  </button>
                </li>
                <li>
          
                  <Link
                    className="dropdown-item"
                    to="#"
             
                    onClick={() => handleDeleteSelectActionConfirmnew(record)}>
                    <i className="far fa-trash-alt me-2" />
                    Delete
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ),
     
    },
 
   
  ];



  const downloadCSV = (data, filename = 'TwentySixASReport.csv') => {
    //const csv = convertToCSV(data);
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const clickDownload = () => {

    report.getReportDownloadCombine(listType, filteroption, "export","browser","paymentreportexporttwentysix")
      .then((response) => {
        // setInvoices(response.data);
        // setFilteredDataSource(response.data);

       downloadCSV(response);

        console.log(response);

       

      })
      .catch((error) => {
        console.log(error);
      });
  
  };




  // eslint-disable-next-line no-unused-vars
  const [showSelect, setShowSelect] = useState(false);

     // eslint-disable-next-line no-unused-vars
     const [searchTerm, setSearchTerm] = useState(""); 


         // eslint-disable-next-line no-unused-vars
     const [deleteact, setDeleteAct] = useState(null);
     // eslint-disable-next-line no-unused-vars
   
     // eslint-disable-next-line no-unused-vars
     const [showDelete, setShowDelete] = useState(false);

      // eslint-disable-next-line no-unused-vars
     const [editSelect, setEditSelect] = useState(false);

      // eslint-disable-next-line no-unused-vars
     const handaleEditPayment = () => {


      setShowSelect(false);
      setShowPayment(true);
    };



    
  const handleCloseDelete = () => {


    setShowDelete(false);

  };
  

   const handleDeleteSelectAction = () => {
    
     console.log("------handleDelete ===== " + payment);
     console.log(payment);
 
     if (payment) {
       InvoiceService.deletePaymentCustomer(payment)
         .then(() => {
           let mess = `${customtitle} ${staticmessages.INVOICE_DELSUCCESS}`;
           toastrSuccess(mess);
           setShowPayment(false);
           setEditSelect(false);
           setShowDelete(false);
           getTwentySixAS();
         })
         .catch((error) => {
           console.error('Delete Action Failed:', error);
         });
     }
 
   };


     const handleDeleteSelectActionConfirm = () => {
      setDeleteAct(payment);
      console.log(payment);
      setShowDelete(true);
    };

  const handleRowClick = (record) => {
    console.log("handleRowClick 00000---" + JSON.stringify(record, null, 2));
    setShowSelect(true);
    setPaymentData(record);

    setSelectedOption1({value:record.payment_method, label:record.payment_method});
    setSelectedClient({
      value: record.client_id,
      label: record.client_name,
    });

    setStartInvoiceDate(record.payment_date);

  
  };


  const forceMenuClose = () => {  
    setMenu(false);
  };




  const handaleEditPaymentnew = (record) => {

    setPaymentData(record);

    setSelectedOption1({value:record.payment_mode, label:record.payment_mode});
    setSelectedClient({
      value: record.id,
      label: record.client_name,
    });


    setStartInvoiceDate(record.payment_date);

    handaleEditPayment();
   
  };

  const handleDeleteSelectActionConfirmnew = (record) => {
    setPaymentData(record);

    setSelectedOption1({value:record.payment_mode, label:record.payment_mode});
    setSelectedClient({
      value: record.id,
      label: record.party_name,
    });


    setStartInvoiceDate(record.payment_date);

    handleDeleteSelectActionConfirm();
   
  };

 
  if (loading) {
    return <Spinners />; // Display the spinner while loading
  }



  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose} onApplyFilter={handleApplyFilter} searchTerm = {searchTerm} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
        
            <BreadcrumbsReportPayment
              title={staticmessages.REPORT_TSIXAS}
              show={show}
              setShow={setShow}
              clickDownload = {clickDownload}
              openPaymentForm={openPaymentForm}
            />
            <ReportModal setShow={setShow} show={show}   filteroption = {filteroption} setFilterOptions ={setFilterOptions} 
            handleApplyFilter = {handleApplyFilter} handleApplyResetApply = {handleApplyResetApply}  />
            <div className="row">
              <div className="col-sm-12">
                <div className="card invoiceList">
                  <div className="card-body invoiceList">
                    <div className="table-responsive table-hover">
                    <Table
                      className="table"
                      pagination={{
                        total: filteredDataSource.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: false,
                      
                      }}
                      columns={columns}
                      dataSource={filteredDataSource}
                      rowKey={(record) => record.id}
                      onChange={handleTableChange}


                      // onRow={(record) => {
                      //   return {
                      //     onClick: () => handleRowClick(record),
                      //   };
                      // }}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>






<Modal show={showPayment} onHide={handleCloseSelect} >
<Modal.Header >
  <Modal.Title>Receipt</Modal.Title>
</Modal.Header>
<Modal.Body  >

  <div className="row">
    <div className="col-md-12">
      <div className="card-body">
        <div className="border-0 pb-0">
          <div className="row">


          <div className="col-lg-12 col-md-6 col-sm-12">
              <div className="input-block mb-3">
                <label>{staticmessages.CUSTOMER_LABEL_CLIENT}<span className="alertstrik">*</span></label>
                <Select
                                    value={selectedClient}
                                    onChange={handleCustomerChange}
                                    options={formattedOptionsClients(clients)}
                                    placeholder="Select Client"
                                  />
               <small className="error">{errors?.client_id}</small>
              </div>
            </div>


            <div className="col-lg-12 col-md-12 col-sm-12">

            <div className="col-lg-12 col-md-6 col-sm-12">
              <div className="input-block mb-3">
                <label>Payment Method</label>
                  <Select
                    defaultValue={staticmessages.PAYMENT_SETTINGTDS[0]}
                    onChange={handlePamyentMethod}
                    options={staticmessages.PAYMENT_SETTINGTDS}

                    placeholder="Select Payment Method"
                    isDisabled={true} 
     
                  />
                <small className="error">{errors?.payment_method}</small>
              </div>
            </div>

              <div className="input-block mb-3">
                <label>Amount<span className="alertstrik">*</span></label>
                <input
 type="number"
                  className="form-control"
                  placeholder="Enter Amount"

                  onChange={handleChangeAmount}
                  id="amount"
                  name="amount"
                  maxLength={10}
                  value={payment.amount}
                />
                <small className="error">{errors?.amount}</small>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="input-block mb-3">
                <label>Payment Date<span className="alertstrik">*</span></label>
                <DatePicker
                  className="datetimepicker form-control"
                  selected={startInvoiceDate}
                  onChange={(date) => HandaleDate(date)}
                  maxDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                ></DatePicker>
                <small className="error">{errors?.payment_date}</small>
              </div>
            </div>


      

          </div>
        </div>
      </div>
    </div>
  </div>
</Modal.Body>
<Modal.Footer>
  <div className="d-flex w-100 justify-content-between">

{(payment.id) && (
  <Button className="me-2" variant="danger" onClick={handleDeleteSelectActionConfirm}>
    <i className="far fa-trash-alt me-2" />
    Delete
  </Button>
   
  )}

<div>
      <Button className="me-2" variant="primary" onClick={handleCloseSelect}>
        Close
      </Button>
       <Button variant="primary" onClick={handaleSavePayment}>
        Save
      </Button> 
      </div>
      </div>
</Modal.Footer>

</Modal>




<Modal show={showSelect} onHide={handleCloseSelect}>
        <Modal.Header >
          <Modal.Title>View Receipt </Modal.Title>

          
          <div className="d-flex ms-auto align-items-center">
          <button
        type="button"
        className="btn-close ms-2"
        onClick={handleCloseSelect}
        aria-label="Close"
      /></div>
        </Modal.Header>
        <Modal.Body  >

          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <div className="border-0 pb-0">
                  <div className="row">


                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="input-block mb-3">

                      <div><label className="h3" >Client</label></div>
                        <div><label>{payment.client_name}</label></div>


                    
                        {/* <div><label className="h3" >Amount </label></div>
                        <div><label className=" badgefontbig">
                        <span className="badge badgefontbig badge-label bg-success"><i className="mdi mdi-circle-medium"></i>
                          {payment.symbol} {payment.amount}</span></label></div> */}

                      

                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                            <div><label className="h3" >Payment Date </label></div>
                            <div><label>{formatDateISO(payment.payment_date)}</label></div>
                            </div>
                            <div className="col-lg-6 col-lg-6 col-sm-12    ">
                            <div className="pull-lg-right row">
                            <div className="">
                              <div className="h3 d-sm-block d-lg-inline" >Amount</div>
                              <div className=" badgefontbig d-sm-block d-md-inline "> 
                             <span className="badge badgefontbig badge-label  bgsuccess"><i className="mdi mdi-circle-medium"></i>{payment.symbol} {payment.amount}</span></div></div>
                            </div> </div>
                        </div>
                        
                    

                     
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button className="me-2" variant="primary" onClick={handleCloseSelect}>
            Close
          </Button>
          <Button variant="primary" onClick={handaleEditPayment}>
            Edit
          </Button>
        </Modal.Footer> */}
      </Modal>


      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header >
          <Modal.Title>Delete Receipt </Modal.Title>
        </Modal.Header>
        <Modal.Body  >

          <div className="row">
            <div className="col-md-12">
              <div className="card-body">
                <div className="border-0 pb-0">
                  <div className="row">


                    <div className="col-lg-12 col-md-12 col-sm-12">
                    {deleteact && (
                  <p>Are you sure you want to delete? </p>
                )} 
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="me-2" variant="primary" onClick={handleCloseDelete}>
            Cancel
          </Button>

          <Button className="me-2" variant="danger" onClick={handleDeleteSelectAction}>
            Delete
          </Button>

        </Modal.Footer>
      </Modal>




    </>
  );
};





export default TwentySixAS;
