import React, { useState, useEffect } from "react";
import Header from '../layouts/Header';
import Sidebar from '../layouts/Sidebar';

import { settingslogo1 } from '../_components/Imagepath';
import SettingsSidebar from '../layouts/SettingsSidebar';
import staticmessages from '../services/staticmessages';
import UserService from "../services/user.service";
import config from '../services/config';
import alertify from "alertifyjs";
import "../../node_modules/alertifyjs/build/css/alertify.css";
import * as yup from 'yup';
import Resizer from 'react-image-file-resizer'; // Import the image resizer

const UploadLogo = () => {

  const API_LOGOURL = config.API_LOGOURL;
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    fetchLogo();
  }, []);


  // Fetch the existing logo URL when the component mounts
  const fetchLogo = async () => {
    try {
      const response = await UserService.getUserProfile();

      console.error('Error fetching countries:', response);


      if (response.data.company_logo) {
        setLogoUrl(API_LOGOURL + response.data.company_logo);
      }
    } catch (error) {
      console.error('Error fetching logo:', error);
    }
  };




  const step3Schema = yup.object().shape({
    selectedFile: yup
      .mixed()
      .required("Please select an image")
      .test(
        "fileFormat",
        "Unsupported format. Only JPG and PNG files are allowed.",
        (value) => {
          // If there's no file, return true because it's required.
          if (!value) return false;

          // Check the file type by accessing the file's MIME type.
          const supportedFormats = ["image/jpeg", "image/png"];
          return value && supportedFormats.includes(value.type);
        }
      ),
  });

  const validateStep = async () => {
    alertify.set("notifier", "position", "top-center");
    const data = { selectedFile };
    try {
      await step3Schema.validate(data, { abortEarly: false });
      setErrors({});
      return data; // Return validated data
    } catch (err) {
      const newErrors = {};
      err.inner.forEach((error) => {
        newErrors[error.path] = error.message;
        alertify.error(error.message);
      });
      setErrors(newErrors);
      return null;
    }
  };

  

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   // Validate the form before uploading
  //   const validatedData = await validateStep();

  //   if (validatedData) {
  //     try {
  //       const formData = new FormData();
  //       formData.append('file', selectedFile); // Add the selected file to the FormData object

  //       const tempResponse = await UserService.uploadLogo(formData); // Use FormData to send the file
  //       if (tempResponse.data.company_logo) {
  //         setLogoUrl(API_LOGOURL + tempResponse.data.company_logo);
  //         toastrSuccess("Logo uploaded successfully!");
  //         window.location.reload();
  //       } else {
  //         alertify.error("Failed to upload the logo.");
  //       }
  //     } catch (error) {
  //       alertify.error("API Error: Failed to save data. Please try again.");
  //       console.error("API Error:", error);
  //     }
  //   } else {
  //     alertify.error("Please resolve the errors in the form.");
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      alertify.set("notifier", "position", "top-center");
      alertify.error("Please select a file first!");
      
      return;
    }

    const validatedData = await validateStep();

    if (validatedData) {


      try {
        const response = await UserService.uploadLogo(selectedFile);
        console.log('File uploaded successfully 222:', response.data.company_logo);
        // Update the logo URL to display the newly uploaded logo

        setLogoUrl(API_LOGOURL + response.data.company_logo);

        if (response && response.data.company_logo) {


          localStorage.setItem('profileImage', response.data.company_logo);
          
          
          window.location.reload();
 
 
         }
         else if (response &&  response.errorMessage) {
           // Handle error message from server
           throw new Error(response.errorMessage);
         }
         else {
           throw new Error("Unexpected response from server.");
         }


       
      } catch (error) {
        alertify.set("notifier", "position", "top-center");
        alertify.error(error.message || "An error occurred while uploading the logo.");
        console.log('Error uploading file:', error);
        // Handle error appropriately
      }
    } else {
      
      alertify.set("notifier", "position", "top-center");
    //  alertify.error(errors);
    }

  };

  const validateImage = (file) => {
    const supportedFormats = ["image/jpeg", "image/png"];
    const maxFileSize = 5 * 1024 * 1024; // 2MB

    if (!supportedFormats.includes(file.type)) {
      alertify.set("notifier", "position", "top-center");
      alertify.error("Unsupported format. Only JPG and PNG files are allowed.");
      return false;
    }
    if (file.size > maxFileSize) {
      alertify.set("notifier", "position", "top-center");
      alertify.error("File size exceeds 2MB limit.");
      return false;
    }
    return true;
  };
    // eslint-disable-next-line no-unused-vars
    const handleFileChange = (event) => {

      
      const file = event.target.files[0];
      if (file) {
        // Validate the file type and size
        if (!validateImage(file)) {
          setSelectedFile(null); // Clear selected file if validation fails
          return; // Stop further processing
        }
      if (file) {
        // Resize the image before uploading
        Resizer.imageFileResizer(
          file,
          500, // Max width
          500, // Max height
          'JPEG', // Output format
          70, // Quality (0-100)
          0, // Rotation
          (uri) => {
            const resizedFile = new File([uri], file.name, { type: file.type });
            setSelectedFile(resizedFile);
            console.log('Error fetching logo:', file);
            const fileUrl = URL.createObjectURL(resizedFile);
            setLogoUrl(fileUrl);
          },
          'blob' // Output type
        );
      }

     
    }
     
    };
    // eslint-disable-next-line no-unused-vars
    const toastrSuccess = (message) => {
      alertify.set("notifier", "position", "top-center");
      alertify.success(message);
    };


  const handleDelete = () => {

    UserService.DeleteLogo()
      .then(() => {
        setLogoUrl(null);
        console.error('Error uploading file:', "error");
      })
      .catch((error) => {
        console.error('Login failed:', error);
      });

  };

  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const forceMenuClose = () => {  
    setMenu(false);
  };

  
  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} forceMenuClose={forceMenuClose} />
        <Sidebar />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}
            {/* /Page Header */}
            <div className="row">
              <div className="col-xl-3 col-md-4">
                <SettingsSidebar />
              </div>


              <div className="col-xl-9 col-md-8">
                <div className="card company-settings-new">
                  <div className="card-body w-100">
                    <div className="content-page-header">
                      <h5>{staticmessages.SETTING_UPLOADLOGO}</h5>
                    </div>
                    <div className="row">

                      <div className="col-lg-12 col-12">
                        <div className="input-block mb-3">
                          <label>{staticmessages.SETTING_UPLOADLOGO}</label>
                          <div className="input-block service-upload logo-upload mb-0">
                            <div className="drag-drop">
                              <h6 className="drop-browse align-center">
                                <span className="text-info me-1">Click To Replace</span>{" "}
                                or Drag and Drop
                              </h6>
                              <p className="text-muted">
                                PNG, JPG (Max 500*500px)
                              </p>
                              <input type="file"
                                id="fileInput"
                                onChange={handleFileChange}
                              />
                            </div>
                            <span className="sites-logo">
                              {logoUrl ? (
                                <div className="mt-4">

                                  <img src={logoUrl} alt="Company Logo" className="img-fluid" />
                                </div>
                              ) : (
                                <img src={settingslogo1} alt="upload" />
                              )}




                            </span>
                          </div>
                        </div>
                      </div>



                      <div className="col-lg-12">
                        <div className="btn-path text-end">

                          <button
                            type="button"
                            data-bs-toggle="modal" data-bs-target="#delete_modal"
                            className="btn btn-rounded btn-outline-danger me-2"
                          >
                            Remove
                          </button>


                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                          >
                            Save Changes
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal custom-modal fade" id="delete_modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Remove {staticmessages.SETTING_UPLOADLOGOD}</h3>
                  <p>Are you sure you want to remove?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="reset"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary paid-continue-btn"
                        onClick={() => handleDelete()}
                      >
                        Yes
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="submit"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary paid-cancel-btn"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}
export default UploadLogo;